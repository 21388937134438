$(function() {
  // Tooltips

  $(".tooltip, .unstyled-tooltip, [rel=tooltip], .tooltip-top[rel=tooltip]").tooltip();

  $(".left-unstyled-tooltip").tooltip({
    placement: 'left'
  });

  $(".disabled").tooltip({
    placement: 'bottom'
  });

  $(".sort_link:not([data-no-default-tooltip])").tooltip({
    placement: 'top',
    title: 'Click to sort'
  });

  // Popovers
  $("a[rel=popover]").popover({
    html: true
  });

  $(".unstyled-popover").popover({
    trigger: 'hover',
    placement: 'top',
    html: true,
    animation: false
  });

  $('.file-information').popover({
    trigger: 'hover',
    placement: 'right',
    html: true
  });

  $('.product-documents-popover').popover();
});
