import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["editor"]

  get extraToolbar() {
    let extraToolbarTmp = this.data.get('extraToolbar')

    if (extraToolbarTmp) {
      return extraToolbarTmp
    } else { return ""; }
  }

  initialize() {
    this.defaults = {
      autoresize_bottom_margin: 10,
      branding: false,
      browser_spellcheck: true,
      content_css: "document",
      content_style: 'html { font-family: "Open Sans", sans-serif; font-size: 14px; line-height: 1.5; } .text-danger { color: red; }',
      contextmenu: false,
      element_format: 'html',
      invalid_elements: 'span div script',
      link_context_toolbar: true,
      max_height: 700,
      min_height: 200,
      menubar: false,
      // paste_as_text: true,
      paste_block_drop: false,
      paste_data_images: false,
      plugins: `code link lists autoresize wordcount`,
      promotion: false,
      relative_urls: false,
      skin: false,
      statusbar: false,
      toolbar: [
        `bold italic underline | bullist numlist | indent outdent ${ this.extraToolbar }`
      ],
      valid_elements: "a[href|target=_blank|target:_blank],p,strong,b,strong/b,strong[class],b[class],ul,ol,li,em,i,br",
    }
  }

  connect () {
    let config = Object.assign({ target: this.editorTarget }, this.defaults)

    tinymce.init(config)
  }

  disconnect () {
    tinymce.remove()
  }
}
