import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'
import _debounce from "lodash/debounce"

export default class extends Controller {
  static targets = [
    'errorMessagesContainer',
    'reservationDateField',
    'reservationTimeField',
    'reservationDateErrorMessage',
    'reservationDateSuccessMessage',
    'loadingIndicator',
    'failureIndicator',
    'actionButton'
  ]

  static values = {
    failed: Boolean
  }

  get validationUrl() { return this.data.get('validation-url') }

  get elementFormData() {
    let _formData = new FormData(this.element)
    return _formData
  }

  // Not in use but keeping around as this is a closer simile to $.serialize()
  get elementFormDataAsParams() {
    let _urlSearchParams = new URLSearchParams(this.elementFormData)
    return _urlSearchParams.toString()
  }

  initialize() {
    // Debounce time change function
    this.throttledValidateReservationTimeChange = _debounce(
      this.throttledValidateReservationTimeChange,
      100
    ).bind(this)
  }

  validate(event) {
    if (this.failedValue) { return }

    this._beginProgressForRemoteRequest()

    let fetchRequest = new FetchRequest('post', this.validationUrl, {
      body: this.elementFormData
    })
    let fetchResponse = fetchRequest.perform()

    fetchResponse.then(response => {
      if (response.ok) {
        return response.json
      } else {
        this.failedValue = true
        return {}
      }

    }).then(response => {
      if (this.failedValue) { return }

      let template = document.createElement('template')
      template.innerHTML = response.html
      let content = template.content.firstChild

      const endDateRadios = content.querySelector('#reservation-end-date-radios')

      if (response.valid) {
        this.errorMessagesContainerTarget.classList.add('hidden')
        this.reservationDateErrorMessageTarget.classList.add('hidden')

        if (endDateRadios) {
          this.reservationDateSuccessMessageTarget.innerHTML = endDateRadios.innerHTML
          this.reservationDateSuccessMessageTarget.classList.remove('hidden')
        }

      } else {
        this.displayErrorMessages(response.errors)

        const hasReservationDateError = response.fields.includes('reservation_date')
        const hasReservationTimeError = response.fields.includes("reservation_time")

        if (hasReservationDateError || hasReservationTimeError) {
          // If there are errors on the start reservation date/time fields
          this.reservationDateErrorMessageTarget.classList.remove('hidden')

          this.reservationDateSuccessMessageTarget.innerHTML = ''
          this.reservationDateSuccessMessageTarget.classList.add('hidden')

        } else {
          // If there are errors on the end reservation date/time fields
          this.reservationDateErrorMessageTarget.classList.add('hidden')

          if (endDateRadios) {
            this.reservationDateSuccessMessageTarget.innerHTML = endDateRadios.innerHTML
            this.reservationDateSuccessMessageTarget.classList.remove('hidden')
          }
        }
      }

      this._endProgressForRemoteRequest()
    })
  }

  validateReservationDateChange(event = null) {
    if (this.hasReservationTimeFieldTarget) {
      if (this.reservationTimeFieldTarget.value) { this.validate() }
    } else {
      this.validate()
    }
  }

  throttledValidateReservationTimeChange(event, element) {
    this.validateReservationTimeChange(event, element)
  }

  validateReservationTimeChange(event, element) {
    if (this.reservationTimeFieldTarget.value) {
      if (this._reservationDateFieldValue()) {
        this.validate()
      }
    }
  }

  _beginProgressForRemoteRequest() {
    this._toggleLoadingIndicatorVisibility(true)
    this._toggleActionButtonsEnabled(false)

    this.errorMessagesContainerTarget.classList.add('hidden')
    this.errorMessagesContainerTarget.innerHTML = ''
  }

  _endProgressForRemoteRequest() {
    this._toggleLoadingIndicatorVisibility(false)
    this._toggleActionButtonsEnabled(true)
  }

  displayErrorMessages(messages) {
    const formattedErrors = messages.flat().map(message => `<div>${ message }</div>`)
    const wrappedErrors = `
      <div class="alert alert-error alert-block">
        ${ formattedErrors.join('') }
      </div>
    `

    this.errorMessagesContainerTarget.innerHTML = wrappedErrors
    this.errorMessagesContainerTarget.classList.remove('hidden')
  }

  _reservationDateFieldValue() {
    return this.reservationDateFieldTarget.querySelector('input').value
  }

  _toggleLoadingIndicatorVisibility(visible) {
    this.loadingIndicatorTarget.classList.toggle('hidden', !visible)
  }

  _toggleActionButtonsEnabled(enabled) {
    this.actionButtonTargets.forEach(actionButton => {
      actionButton.toggleAttribute('disabled', !enabled)
      actionButton.classList.toggle('disable', !enabled)
    })
  }

  failedValueChanged(newValue) {
    if (newValue) {
      this.loadingIndicatorTarget.remove()

      this.failureIndicatorTarget.classList.remove('hidden')
      this.failureIndicatorTarget.dispatchEvent(new CustomEvent('showTooltip'))

      this.actionButtonTargets.forEach(actionButton => actionButton.remove())
    }
  }
}
