import { Controller } from "stimulus"
// @dependency jQuery
// @dependency jQuery Sortable
// @dependency Cocoon

export default class extends Controller {
  static targets = [
    'orderField',
    'sortable',
    'insertable'
  ]

  initialize() {
    $(this.element).sortable({
      placeholder: 'ui-state-highlight',
      handle: '.handle',
      axis: 'y',
      stop: (event, ui) => this.setValues()
    })

    this._configureCocoonCallbacks()
    this.setValues()
  }

  setValues() {
    this.orderFieldTargets.forEach((element, index) => {
      element.value = index + 1
    })
  }

  _configureCocoonCallbacks() {
    if (this.hasInsertableTarget) {
      this._setCocoonCallback($(this.insertableTarget))
    } else {
      this._setCocoonCallback($(this.element))
    }
  }

  _setCocoonCallback($target) {
    $target.on('cocoon:after-insert', () => this.setValues())
  }
}