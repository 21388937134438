import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['clearable']

  clear(event) {
    event.preventDefault()
    this.clearableTargets.forEach((target) => target.value = '')
  }
}
