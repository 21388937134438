import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'checkoutStrategyRadioButton',
    'section',
      'dailyFields',
      'hourlyFields',
      'sameDayFields',
      'longTermFields',
    'consecutiveReservationFieldset'
  ]

  initialize() {
    this.render()
  }

  render() {
    this.sectionTargets.forEach(target => { target.classList.add('d-none') })

    this.checkoutStrategyRadioButtonTargets.forEach(radioButton => {
      if (radioButton.checked) {
        this.targets.find(radioButton.dataset.toggles).classList.remove('d-none')

        this.consecutiveReservationFieldsetTarget.classList.toggle(
          'd-none',
          radioButton.dataset.allowConsecutiveReservations != "true"
        )
      }
    })
  }
}
