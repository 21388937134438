import { Controller } from 'stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['container']

  get url() {
    return this.data.get('url')
  }

  // Set to "true" to only load remote data one time
  get once() {
    return (this.data.get('once') == 'true')
  }

  async load() {
    if (!this.hasContainerTarget) { return }

    const request = new FetchRequest('get', this.url, { responseKind: 'application/html' })
    const response = await request.perform()

    if (response.ok) {
      this.containerTarget.innerHTML = await response.html

      if (this.once) {
        this.containerTarget.removeAttribute('data-load-remote-content-target')
      }
    }
  }
}
