import { Controller } from "stimulus"
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  connect() {
    new Tagify(this.element, {
      pattern: /^[-a-zA-Z0-9\-_]+$/,
      originalInputValueFormat: ((valuesArr) => {
        return valuesArr.map((item) => item.value).join(',')
      })
    })
  }
}