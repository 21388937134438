import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'package',
    'deactivateAllButton',
    'activateAllButton'
  ]

  static values = {
    failed: Boolean
  }

  activateAll(event) {
    event.preventDefault()
    if (this.failedValue) { return }
    this._toggle(this.activateAllButtonTarget, "true")
  }

  deactivateAll(event) {
    event.preventDefault()
    if (this.failedValue) { return }
    this._toggle(this.deactivateAllButtonTarget, "false")
  }

  _toggle(buttonTarget, packageDataValue) {
    this._toggleButtonStatuses(false)

    let fetchRequest = new FetchRequest(
      'post',
      buttonTarget.getAttribute('href')
    )

    fetchRequest.perform().then(response => {
      // We only really care about a successful response; there's nothing
      // of note in the body.
      if (response.ok) {
        this.packageTargets.forEach(target => {
          target.dataset.packageAdminActionsHandlerActiveAndVisibleValue = packageDataValue
        })

        this._toggleButtonStatuses(true)

      } else {
        this.failedValue = true
        buttonTarget.classList.values = 'btn'
        buttonTarget.innerHTML = `<i class='fa fa-exclamation-triangle'></i>`
      }
    })
  }

  _toggleButtonStatuses(enabled = true) {
    this.activateAllButtonTarget.toggleAttribute('disabled', !enabled)
    this.deactivateAllButtonTarget.toggleAttribute('disabled', !enabled)
  }
}
