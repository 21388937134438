import { Controller } from "stimulus"
// @dependency Rails UJS

export default class extends Controller {
  static targets = ['form', 'checkbox', 'loading']

  click(event) {
    if (this.checkboxTarget.classList.contains('hidden')) { return false }
    if (!this.loadingTarget.classList.contains('hidden')) { return false }

    // If the click event was intercepted by the checkbox, skip it
    if (event.target != this.checkboxTarget) {
      event.preventDefault()
      this.checkboxTarget.checked = !this.checkboxTarget.checked
    }

    Rails.fire(this.formTarget, 'submit')
  }

  ajaxBeforeSend() {
    this.checkboxTarget.setAttribute('disabled', 'disabled')
    this.checkboxTarget.classList.add('hidden')
    this.loadingTarget.classList.remove('hidden')
  }

  update(event) {
    const isMarked = JSON.parse(event.detail[2].response).marked
    const markedCount = JSON.parse(event.detail[2].response).marked_count

    this.checkboxTarget.checked = isMarked
    this.checkboxTarget.removeAttribute('disabled')
    this.loadingTarget.classList.add('hidden')
    this.checkboxTarget.classList.remove('hidden')
    this.element.closest('[data-controller~="package-label-table"]')
      .dataset.packageLabelTableMarkedCountValue = markedCount
  }
}