import { Controller } from "stimulus"
import _flatMap from "lodash/flatMap"
import { FetchRequest } from '@rails/request.js'
// @dependency jQuery
// @dependency Typeahead

export default class extends Controller {
  connect() {
    this.begunSourceCaching = false
    this.sourceCache = null

    // Prevent autocompletes and autofills
    this.element.setAttribute('autocomplete', 'off')
    this.element.setAttribute('autocapitalize', 'off')
    this.element.setAttribute('spellcheck', 'false')
    this.element.dataset.lpignore = 'true'

    $(this.element).typeahead({
      highlight: true,
      hint: true,
      minLength: 2,
      source: (query, process) => {
        if (this.sourceCache || this.begunSourceCaching) {
          return this.sourceCache
        } else {
          this.begunSourceCaching = true
          this.fetchSource(query, process)
        }
      }
    })

  }

  fetchSource(query, process) {
    new FetchRequest('post', this.data.get('url'), {
      responseKind: 'json'
    }).perform()
      .then(response => { return response.json })
      .then(json => {
        this.sourceCache = json.map(eachItem => eachItem.label)
        process(this.sourceCache)
      })
  }
}
