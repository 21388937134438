import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'
// @dependency PackageImportReviewController

export default class extends Controller {
  static targets = [
    'toggleButton',
    'waitingText'
  ]

  static values = {
    activeAndVisible: Boolean
  }

  get deactivationText()  { return 'Deactivate and Hide' }
  get deactivationClass() { return 'btn-danger' }
  get activationText()    { return 'Activate and Show' }
  get activationClass()   { return 'btn-success' }

  initialize() {
    this.render()
  }

  render(event) {
    if (event) { event.preventDefault() }

    if (this.activeAndVisibleValue) {
      this.toggleButtonTarget.classList.remove(this.activationClass)
      this.toggleButtonTarget.classList.add(this.deactivationClass)
      this.toggleButtonTarget.innerHTML = this.deactivationText

    } else {
      this.toggleButtonTarget.classList.add(this.activationClass)
      this.toggleButtonTarget.classList.remove(this.deactivationClass)
      this.toggleButtonTarget.innerHTML = this.activationText
    }
  }

  togglePackage(event) {
    event.preventDefault()

    this.toggleButtonTarget.classList.remove(this.deactivationClass, this.activationClass)
    this.toggleButtonTarget.setAttribute('disabled', 'disabled')
    this.toggleButtonTarget.innerHTML = this.waitingTextTarget.innerHTML

    let fetchRequest = new FetchRequest(
      'post',
      this.toggleButtonTarget.getAttribute('href')
    )

    fetchRequest.perform().then(response => {
      // We only really care about a successful response; there's nothing
      // of note in the body.
      if (response.ok) {
        this.toggleButtonTarget.removeAttribute('disabled')
        this.activeAndVisibleValue = !this.activeAndVisibleValue
      } else {
        this.toggleButtonTarget.innerHTML = `<i class='fa fa-exclamation-triangle'></i>`
      }
    })
  }

  activeAndVisibleValueChanged(newValue) {
    this.render()
  }
}
