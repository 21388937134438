import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'itemRadio',
      'itemFields',
    'locationRadio',
      'locationFields'
  ]

  initialize() {
    if (this.hasItemRadioTarget && this.hasLocationRadioTarget) {
      /*
        If radio buttons exist (i.e. the user has not selected whether
        this is an equipment or a facility package), proceed with the render
        process.
      */
      this.render()

    } else if (this.hasItemFieldsTarget) {
      /*
        If the this.itemFieldsTarget exists, it is persisted with equipment
        selected. There is no changing how this works at this point.
      */
      this.itemFieldsTarget.classList.remove('hidden')

    } else if (this.hasLocationFieldsTarget) {
      /*
        If the this.locationFieldsTarget exists, it is persisted with facilities
        selected. There is no changing how this works at this point.
      */
      this.locationFieldsTarget.classList.remove('hidden')
    }
  }

  render() {
    this.itemFieldsTarget.classList.add('hidden')
    this.locationFieldsTarget.classList.add('hidden')

    if (this.locationRadioTarget.checked || this.itemRadioTarget.checked) {
      this.itemFieldsTarget.classList.toggle('hidden', this.locationRadioTarget.checked)
      this.locationFieldsTarget.classList.toggle('hidden', this.itemRadioTarget.checked)
    }
  }

  selectItems(event) {
    event.preventDefault()
    this.render()
  }

  selectLocations() {
    event.preventDefault()
    this.render()
  }
}
