import { Controller } from "stimulus"
import _template from "lodash/template"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'statusMessage',
      'pendingStatusMessage',
      'inProcessStatusMessage',
      'completedStatusMessage',
      'failedStatusMessage',

    'inProcessStatusMessageTemplate',
    'completedStatusMessageTemplate',
    'failureMessageTemplate',
    'partialFailureMessageTemplate',

    'progressBarContainer',
      'progressBar',

    'errorMessagesContainer',
      'errorMessages',

    'actionsContainer'
  ]

  static values = {
    completed: Boolean,
    failed: Boolean,
    failureReason: String,
    failureCopy: String,
    remoteRequestFailed: Boolean,
    processState: String,
    rowsTotalCount: Number,
    rowsProcessedCount: Number,
    rowsProcessedSuccessfullyCount: Number,
    rowsFailedCount: Number,
    percentageComplete: Number,
  }

  get hasFailedRows() { return this.rowsFailedCountValue > 0 }
  get hasNoFailedRows() { return this.rowsFailedCountValue == 0 }
  get statusUrl() { return this.data.get('url') }
  get packageImportFileId() { return this.data.get('id') }
  get requestData() { return JSON.stringify({ id: this.packageImportFileId }) }

  initialize() {
    this._resetStatusMessages()
    this.timer = setInterval(() => this.pollStatus(), 1000)
  }

  pollStatus() {
    const fetchRequest = new FetchRequest('post', this.statusUrl, {
      body: this.requestData
    });

    fetchRequest.perform().then(response => {
      if (response.ok) {
        return response.json
      }

      this.remoteRequestFailedValue = true
      clearInterval(this.timer)
      return {}

    }).then(response => {
      if (this.remoteRequestFailedValue) { return }

      this._resetStatusMessages()
      this._updateValues(response)

      this.processStateValue = response.process_state
      this._processStateValue()

    })
  }

  _resetStatusMessages() {
    this.statusMessageTargets.forEach(statusMessageTarget => {
      statusMessageTarget.classList.add('hidden')
    })
  }

  _updateValues(response) {
    // TOTAL ROWS (SUCCESSFUL ONLY!)
    if (response.rows) { this.rowsTotalCountValue = response.rows }

    // SUCCESSFUL ROWS PROCESSED
    if (response.rows_processed) {
      this.rowsProcessedSuccessfullyCountValue = response.rows_processed
    }

    // TOTAL ROWS PROCESSED
    if (response.total_rows_processed) {
      self.rowsProcessedCountValue = response.total_rows_processed
    }

    // PERCENTAGE COMPLETE
    this.percentageCompleteValue = response.percentage_complete

    // TOTAL ROWS FAILED
    if (response.rows_failed) {
      this.rowsFailedCountValue = response.rows_failed
    }

    // FAILURE DETAILS
    this.failureReasonValue = response.failure_reason
    this.failureCopyValue = response.failure_copy
  }

  _processStateValue() {
    let data = {}

    switch(this.processStateValue) {
      case 'pending':
        this._showStatusMessage(this.pendingStatusMessageTarget)
        break

      case 'processing':
        // Increment rowsProcessed by 1 to appear as if we are working on
        // the next package in the collection
        data = {
          rowsProcessed: this.rowsProcessedCountValue + 1,
          totalRows: this.rowsTotalCountValue,
          rowsFailed: this.rowsFailedCountValue,
        }

        this.inProcessStatusMessageTarget.innerHTML = this._inProcessStatusMessageTemplate(data)
        this._showStatusMessage(this.inProcessStatusMessageTarget)
        break

      case 'complete':
        if (this.hasFailedRows) {
          this._setProgressBarClass('bar-warning')
        } else {
          this._setProgressBarClass('bar-success')
        }

        data = {
          successfulRowsProcessed: this.rowsProcessedSuccessfullyCountValue,
          totalRowsProcessed: this.rowsTotalCountValue,
          totalRowsFailed: this.rowsFailedCountValue,
        }

        this.completedStatusMessageTarget.innerHTML = this._completedStatusMessageTemplate(data)
        this._showStatusMessage(this.completedStatusMessageTarget)
        this.completedValue = true
        break

      case 'failed':
        this._setProgressBarClass('bar-danger')
        this._showStatusMessage(this.failedStatusMessageTarget)
        this.completedValue = true
        break

    }
  }

  percentageCompleteValueChanged(newValue) {
    this.progressBarTarget.style.width = `${ newValue }%`

    if (newValue == "100" || newValue == 100) {
      this.progressBarContainerTarget.classList.remove('active', 'progress-striped')
    }
  }

  completedValueChanged(newValue) {
    if (newValue != true) { return }

    // Kill the timer and set the progress bar to 100%
    clearInterval(this.timer)
    this.percentageCompleteValue = 100

    // Gather any errors
    let errorMessages = []

    if (this.processStateValue == "failed") {
      let data = { failureReason: this.failureReasonValue }
      errorMessages.push(this._failureMessageTemplate(data))
    }

    if (this.hasFailedRows) {
      let data = {
        rowsFailed: this.rowsFailedCountValue,
        failureCopy: this.failureCopyValue,
      }
      errorMessages.push(this._partialFailureMessageTemplate(data))
    }

    // Print errors if any are present
    if (errorMessages.length) {
      this.errorMessagesTarget.innerHTML = errorMessages.join('')
      this.errorMessagesContainerTarget.classList.remove('hidden')
    }

    // Show actions if applicable
    if (this.rowsProcessedSuccessfullyCountValue > 0) {
      this.actionsContainerTarget.classList.remove('hidden')
    }
  }

  _inProcessStatusMessageTemplate(data) {
    return _template(this.inProcessStatusMessageTemplateTarget.innerHTML)(data)
  }

  _completedStatusMessageTemplate(data) {
    return _template(this.completedStatusMessageTemplateTarget.innerHTML)(data)
  }

  _failureMessageTemplate(data) {
    return _template(this.failureMessageTemplateTarget.innerHTML)(data)
  }

  _partialFailureMessageTemplate(data) {
    return _template(this.partialFailureMessageTemplateTarget.innerHTML)(data)
  }

  _showStatusMessage(target) {
    target.classList.remove('hidden')
  }

  _setProgressBarClass(...classList) {
    this.progressBarTarget.classList.value = 'bar'
    this.progressBarTarget.classList.add(...classList)
  }
}