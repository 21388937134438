import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "collapseContainer",
    "whenVisibleButton",
    "whenHiddenButton"
  ]

  static values = {
    visible: Boolean,
  }

  visibleValueChanged(event) {
    this.collapseContainerTarget.classList.toggle('hidden', !this.visibleValue)
    this.whenVisibleButtonTarget.classList.toggle('hidden', !this.visibleValue)
    this.whenHiddenButtonTarget.classList.toggle('hidden', this.visibleValue)
  }

  toggle(event) {
    if (event) { event.preventDefault() }
    this.visibleValue = !this.visibleValue
  }
}