import { Controller } from "stimulus"

/*
  @example

    %div{ data: { controller: "control-toggle" }}
      %input{ type: :radio, name: :dorp, data: { control_toggle_target: 'control', action: "control-toggle#update", "control-toggle-container-target": "beep" } }
      %input{ type: :radio, name: :dorp, data: { control_toggle_target: 'control', action: "control-toggle#update", "control-toggle-container-target": "boop" } }

      %div{ data: { control_toggle_target: 'container beep' }} I'm the beep
      %div{ data: { control_toggle_target: 'container boop' }} I'm the boop
*/
export default class extends Controller {
  static targets = [
    "control",
    "container"
  ]

  initialize() {
    this.update()
  }

  update() {
    // Hide all the containers initially
    this.containerTargets.forEach(target => target.hidden = true)

    // Initialize a collection of containers to show.
    let showContainers = []

    // Collect the targets that should be visible
    this.controlTargets.forEach(control => {
      if (control.checked) {
        let containerTargetToShow = control.dataset.controlToggleContainerTarget

        if (containerTargetToShow) {
          showContainers.push(containerTargetToShow)
        }
      }
    })

    // Show the containers that should be shown
    if (showContainers.length) {
      showContainers.forEach(container => {
        const containerToShow = this.targets.find(container)
        if (containerToShow) { containerToShow.hidden = false }
      })
    }
  }
}