import { Controller } from "stimulus"

/*
  Similar to toggle_controller, but also swaps the text with two additional
  targets: this.openTextTarget is shown when the toggle area is open, and
  this.closedTextTarget is shown when the toggle are is closed.
 */
export default class extends Controller {
  static targets = [
    "openText", "closedText",
    "toggleable"
  ]

  initialize() {
    this.render()
  }

  toggle(event) {
    event.preventDefault()
    this.toggleableTarget.classList.toggle('hidden')
    this.render()
  }

  render() {
    this.openTextTarget.classList.toggle('hidden', this.toggleableTarget.classList.contains('hidden'))
    this.closedTextTarget.classList.toggle('hidden', !this.toggleableTarget.classList.contains('hidden'))
  }
}
