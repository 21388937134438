import { Controller } from "stimulus"
// @dependency Rails UJS

export default class extends Controller {
  static targets = ['row', 'checkbox', 'counter', 'loading', 'downloadMarkedLink']

  static values = {
    markedCount: Number
  }

  ajaxBeforeSend() {
    this._disableCheckboxes()
  }

  selectAll(event) {
    const packageIds = JSON.parse(event.detail[2].response).package_ids
    const markedCount = JSON.parse(event.detail[2].response).marked_count
    const domIds = packageIds.map((id) => `package_${id}`)

    this.rowTargets.forEach((target) => {
      if (domIds.includes(target.getAttribute('id'))) {
        target.querySelector('input[type="checkbox"]').checked = true
      }
    })

    this.markedCountValue = markedCount
    this._enableCheckboxes()
  }

  deselectAll(event) {
    const packageIds = JSON.parse(event.detail[2].response).package_ids
    const markedCount = JSON.parse(event.detail[2].response).marked_count
    const domIds = packageIds.map((id) => `package_${id}`)

    this.rowTargets.forEach((target) => {
      if (domIds.includes(target.getAttribute('id'))) {
        target.querySelector('input[type="checkbox"]').checked = false
      }
    })

    this.markedCountValue = markedCount
    this._enableCheckboxes()
  }

  _disableCheckboxes() {
    this.checkboxTargets.forEach((target) => {
      target.setAttribute('disabled', 'disabled')
      target.classList.add('hidden')
    })
    this.loadingTargets.forEach((target) => target.classList.remove('hidden'))
  }

  markedCountValueChanged(count) {
    this.downloadMarkedLinkTarget.classList.toggle('!link-disabled', count == 0)
    if (count == 1) {
      this.counterTarget.innerText = "1 marked package"
    } else {
      this.counterTarget.innerText = `${count} marked packages`
    }
  }

  _enableCheckboxes() {
    this.loadingTargets.forEach((target) => target.classList.add('hidden'))
    this.checkboxTargets.forEach((target) => {
      target.classList.remove('hidden')
      target.removeAttribute('disabled')
    })
  }
}
