import { Controller } from 'stimulus'
import _isEmpty from 'lodash/isEmpty'
import _reject from 'lodash/reject'

export default class extends Controller {
  static targets = [
    'searchForm',
    'searchField',
    'tocEntry',
    'faqEntry'
  ]

  get query() {
    return this.searchFieldTarget.value.replace(/[^a-zA-Z@\d]/g, '').toLowerCase().trim()
  }

  get queryWords() {
    return _reject(
      this.searchFieldTarget.value.split(' ').map(word => {
        return word.replace(/[^a-zA-Z@\d]/g, '').toLowerCase().trim()
      }),
      x => _isEmpty(x)
    )
  }

  get hasQuery() { return !_isEmpty(this.query) }
  get doesNotHaveQuery() { return !this.hasQuery }

  connect() {
    /*
      Done in the view instead; retaining for historical knowledge

      this.faqEntryTargets.forEach(target => {
        target.dataset.searchContent = target.innerText.replace(/[^a-zA-Z@\d]/g, '').toLowerCase()
      })
    */
    this.searchFormTarget.addEventListener('submit', event => event.preventDefault())
  }

  inlineSearch() {
    if (this.doesNotHaveQuery) {
      this.tocEntryTargets.forEach(target => target.classList.remove('faq-d-none'))
      this.faqEntryTargets.forEach(target => target.classList.remove('faq-d-none'))
      return
    }

    this.faqEntryTargets.forEach(target => {
      target.classList.toggle('faq-d-none', !this._containsAllSearchTerms(target))
      const tocEntry = document.getElementById(target.dataset.toc)

      if (tocEntry) {
        tocEntry.classList.toggle('faq-d-none', !this._containsAllSearchTerms(target))
      }
    })
  }

  _containsAllSearchTerms(target) {
    return this.queryWords.every((element, index, ary) => {
      return target.dataset.searchContent.includes(element)
    })
  }
}
