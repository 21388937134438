import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["collectionCheckboxes", "label"]

  expand(event) {
    event.preventDefault()
    this.collectionCheckboxesTarget.classList.remove('hidden')
    this.labelTarget.classList.add('hidden')
  }
}
