import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'timeEntryField',
    'openAtFields',
    'openCheckbox',
    'closeCheckbox',
  ]

  initialize() {
    this.render()
  }

  render() {
    this.timeEntryFieldTargets.forEach((target) => {
      this._renderTimeFieldTarget(target, this.openCheckboxTarget.checked)
    })
    this.openAtFieldsTarget.classList.toggle('hidden', !this.openCheckboxTarget.checked)
  }

  _isOpened() {
    this.openCheckboxTarget.checked
  }

  _renderTimeFieldTarget(target, isOpen) {
    target.classList.toggle('disabled', !isOpen)
    target.classList.toggle('disabled--obfuscate', !isOpen)
    target.toggleAttribute('disabled', !isOpen)
    target.toggleAttribute('readonly', !isOpen)
  }
}
