import { Controller } from "stimulus"

/*
  Reusable delegate to BS tooltip, replacing the myriad of tooltip methods.
*/
export default class extends Controller {
  connect() {
    $(this.element).tooltip({
      animation: (this.element.dataset.animation || true),
      container: (this.element.dataset.container || false),
      delay: {
        hide:    (this.element.dataset.delayHide || 0),
        show:    (this.element.dataset.delayShow || 0),
      },
      html:      (this.element.dataset.html      || false),
      placement: (this.element.dataset.placement || 'top'),
      trigger:   (this.element.dataset.trigger   || 'hover focus'),
    })

    if (this.data.get('openImmediately') == 'true') {
      $(this.element).tooltip('show')
    }
  }

  show() {
    $(this.element).tooltip('show')
  }

  disconnect() {
    $(this.element).tooltip('destroy')
  }
}
