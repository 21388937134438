import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dialog']

  initialize() {
    // This bit of code handles closing the dialog with an outside click
    this.dialogTarget.addEventListener('click', ((event) => {
      let rect = event.target.getBoundingClientRect();
      // console.log(`
      //             ClientX: ${event.clientX}
      //             rect.left: ${rect.left}
      //             rect.right: ${rect.right}

      //             ClientY: ${event.clientY}
      //             rect.top: ${rect.left}
      //             rect.bottom: ${rect.bottom}
      // `);
      if (rect.left > event.clientX ||
        rect.right < event.clientX ||
        rect.top > event.clientY ||
        rect.bottom < event.clientY
      ) {
        this.dialogTarget.close()
      }
    }))
  }

  open(event) {
    event.preventDefault()
    this.dialogTarget.showModal()
  }

  close(event) {
    event.preventDefault()
    this.dialogTarget.close()
  }
}
