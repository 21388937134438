import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'allDayCheckbox',
    'openAtFields',
    'openAtTimeField',
    'closeAtTimeField',
  ]

  static values = {
    allDay: Boolean
  }

  initialize() {
    this.render()
  }

  render() {
    this.allDayCheckboxTarget.checked = this.allDayValue
    this._renderTimeFieldTarget(this.openAtTimeFieldTarget)
    this._renderTimeFieldTarget(this.closeAtTimeFieldTarget)
    this.openAtFieldsTarget.classList.toggle('hidden', this.allDayValue)
  }

  toggleAllDay(event) {
    this.allDayValue = this.allDayCheckboxTarget.checked
  }

  allDayValueChanged(newValue) {
    this.render()
  }

  _renderTimeFieldTarget(target) {
    target.classList.toggle('disabled', this.allDayValue)
    target.classList.toggle('disabled--obfuscate', this.allDayValue)
    target.toggleAttribute('disabled', this.allDayValue)
    target.toggleAttribute('readonly', this.allDayValue)
  }
}