import { Controller } from "stimulus"
import _isEmpty from 'lodash/isEmpty'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['input', 'resetButton', 'todayButton']

  get defaultServerDateFormat() { return 'Y-m-d' }
  get defaultFriendlyDateFormat() { return 'm/d/Y' }

  get serverDateFormat() {
    if (_isEmpty(this.element.dataset.dateFormat)) {
      return this.defaultServerDateFormat
    } else {
      return this.element.dataset.dateFormat
    }
  }

  get friendlyDateFormat() {
    if (_isEmpty(this.element.dataset.friendlyDateFormat)) {
      return this.defaultFriendlyDateFormat
    } else {
      return this.element.dataset.friendlyDateFormat
    }
  }

  initialize() {
    let targetElement = this.element
    if (this.hasInputTarget) {
      this.inputTarget.dataset.input = true
    }

    this.datepicker = flatpickr(this.element, {
      dateFormat: this.serverDateFormat,
      defaultDate: this.element.value,
      altInput: true,
      altFormat: this.friendlyDateFormat,
      disableMobile: false,
      allowInput: true,
      wrap: (this.hasInputTarget && this.inputTarget != this.element),
      onChange: (selectedDates, dateStr, instance) => {
        this.element.dispatchEvent(new CustomEvent('datepickerChanged', {}))
      }
    })
  }

  open(event) {
    event.preventDefault()
    this.datepicker.open()
  }

  close(event) {
    if (event && event.type == "keyup" && event.code == "Escape") {
      this.datepicker.close()

      if (this.hasInputTarget) {
        this.inputTarget.blur()
      } else {
        this.element.blur()
      }
    }
  }

  reset(event) {
    event.preventDefault()
    this.datepicker.clear()
  }

  today(event) {
    event.preventDefault()
    this.datepicker.setDate('today', true)
  }
}
