import { Controller } from "stimulus"
// @dependency Rails UJS

export default class extends Controller {
  static targets = ['icon', 'text']
  static values = { marked: Boolean }

  ajaxBeforeSend() {
    this.iconTarget.classList = this._spinnerIconClasses()
    this.element.setAttribute('disabled', 'disabled')
  }

  success(event) {
    this.markedValue = JSON.parse(event.detail[2].response).marked
    this.element.removeAttribute('disabled')
  }

  _updatedText(markedState) {
    if (markedState) {
      return this.data.get('trueText')
    } else {
      return this.data.get('falseText')
    }
  }

  _spinnerIconClasses() {
    return 'fa fa-fw fa-spin fa-spinner'
  }

  _updatedIconClasses(markedState) {
    if (markedState) {
      return `fa fa-fw ${ this.data.get('trueClass') }`
    } else {
      return `fa fa-fw ${ this.data.get('falseClass') }`
    }
  }

  markedValueChanged(newValue) {
    this.iconTarget.classList = this._updatedIconClasses(newValue)
    this.textTarget.innerText = this._updatedText(newValue)
  }
}